.responsive-calendar .ant-picker-panels {
    display: flex;
    flex-direction: row;
}
  
@media (max-width: 768px) {
    .responsive-calendar .ant-picker-panels {
        flex-direction: column !important;
    }

    .responsive-calendar .ant-picker-panel {
        width: 100% !important;
    }
}